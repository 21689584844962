<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <s-toolbar
            :color="'#244093'"
            dark
            label="Reporte de Clientes"
            class="tran"
          ></s-toolbar>
          <v-row style="margin: auto">
            <v-card width="100%">
              <v-row style="margin: auto">
                <v-col cols="6" lg="2" md="2">
                  <v-btn
                    width="100%"
                    rounded
                    :color="'info'"
                    small
                    @click="runView()"
                    >Buscar</v-btn
                  >
                </v-col>
                <v-col cols="6" lg="2" md="2">
                  <v-btn
                    width="100%"
                    rounded
                    :color="'error'"
                    small
                    @click="downloadReport()"
                    >Descargar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-tabs v-model="currentItem">
        <v-tab href="#Dashboard"> Dashboard</v-tab>
        <v-tab href="#ReportCustomer"> Reporte de Clientes</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentItem">
        <v-tab-item :value="'Dashboard'">
          <clc-dashboard-customer
            :items="dataDashboard"
            :items2="dataDashboard2"
          ></clc-dashboard-customer>
        </v-tab-item>
        <v-tab-item :value="'ReportCustomer'">
          <v-col cols="12">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Cuadrante, lote, Dni"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  dense
                  :footer-props="{
                    showCurrentPage: true,
                    showFirstLastPage: true,
                    itemsPerPageOptions: [5, 10, 50, 100],
                  }"
                  class="fixed-first-column"
                  :search="search?.length > 2 ? search : null"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-tab-item>
      </v-tabs-items>

      <v-dialog
        v-model="processing"
        :persistent="messageProcessing.length == 0"
        width="400"
      >
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessing.length == 0">
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar busqueda <br />
              {{ messageProcessing }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import ClcDashboardCustomer from "./ClcDashboardCustomer.vue";

export default {
  components: {
    ClcDashboardCustomer,
  },
  data() {
    return {
      currentItem: "tab-Funciones",
      items: [],
      headers: [],
      report: {},
      search: "",
      processing: false,
      messageProcessing: "",
      group: [],
      dataDashboard: [],
      group2: [],
      dataDashboard2: [],
    };
  },
  mounted() {},
  methods: {
    runView() {
      this.messageProcessing = "";
      this.report.QryNameProcedure = "";
      this.report.QryParamsArray = "Month";
      this.report.QryParamsArrayValue = "Mes"; //this.DateBegin + "," + this.DateEnd + "," + this.number;
      this.report.QryNameProcedure = "ClcReportCustomer_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(this.report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;
              this.items = resp.data;


              this.group = _.groupBy(this.items, "Cuadrante");
              this.group2 = _.groupBy(this.items, "AnioVenta");

              this.dataDashboard2 = [];
              this.dataDashboard = [];

              var i = 0,
                objTmp = {};
              for (let prop in this.group) {
                i = 0;
                objTmp = {};
                this.group[prop].forEach((element) => {
                  i = i + 1;
                });
                objTmp.name = prop;
                objTmp.y = i;
                this.dataDashboard.push(objTmp);
              }

              var j = 0,
                objTmp2 = {};
              for (let prop in this.group2) {
                j = 0;
                objTmp2 = {};
                this.group2[prop].forEach((element) => {
                  j = j + 1;
                });
                objTmp2.name = prop;
                objTmp2.y = j;
                this.dataDashboard2.push(objTmp2);
              }
              this.headers = Object.keys(resp.data[0]).map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
                filterable:
                  item === "Codigo de Venta" || item === "Lote Nuevo"
                    ? true
                    : false,
              }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },

    downloadReport() {
      this.messageProcessing = "";
      this.report.QryNameProcedure = "";
      this.report.QryParamsArray = "Month";
      this.report.QryParamsArrayValue = "mes"; //this.DateBegin + "," + this.DateEnd + "," + this.number;
      this.report.QryNameProcedure = "ClcReportCustomer_R";

      this.processing = true;
      _sQryConfigurationService
        .downloadexcel(this.report, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                "Reporte de Clientes"
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
